<template>
    <v-card style="margin-bottom:1em" class="UsuarioFornecedorFiltros">
      <v-card-title>
        <span class="title">{{ $tc('title.filtro', 2) }}</span>
        <v-spacer></v-spacer>
        <v-icon v-show="filtrosAbertos"
          @click="fixaFiltros"
          :class="filtrosFixosClass">
          fa-thumbtack
        </v-icon>
        <v-badge
        bordered
        color="orange"
        icon="mdi-lock"
        overlap
        v-if="habilitaIconBadge">
        <v-icon @click="toggleFiltros">filter_list</v-icon>
      </v-badge>
      <v-icon v-else @click="toggleFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="filtrosAbertos">
        <v-row :class="layoutClass">
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete class="UsuarioFornecedorFiltros__Autocomplete custom-autocomplete"
              id="grupos-fornecedores"
              v-model="filtrosUsuarioFornecedor.idsGrupoFornecedor"
              :items="itemsGrupoFornecedor"
              :label="`${$tc('label.grupo_fornecedor', 1)}`"
              clearable
              clear-on-select
              item-value="shortCode"
              multiple
              chips
              @click:append="() => triggerSelecao('grupos-fornecedores')"
              deletable-chips>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtrosUsuarioFornecedor.nome"
              :label="`${$tc('label.nome', 1)}`"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtrosUsuarioFornecedor.email"
              :label="`${$tc('label.email', 1)}`"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="filtrosUsuarioFornecedor.nomUsuario"
              :label="`${$tc('label.login', 1)}`"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete class="UsuarioFornecedorFiltros__Autocomplete custom-autocomplete"
              v-model="filtroTipoFornecedor"
              :items="itemsTiposFornecedores"
              :label="`${$tc('label.tipo_fornecedor', 1)}`"
              clearable
              item-value="shortCode"
              multiple
              chips
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete class="UsuarioFornecedorFiltros__Autocomplete custom-autocomplete"
              v-model="filtrosUsuarioFornecedor.indProcurador"
              :items="itemsSimENao"
              :label="`${$tc('label.procurador', 1)}`"
              clearable
              item-value="shortCode"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete class="UsuarioFornecedorFiltros__Autocomplete custom-autocomplete"
              v-model="filtrosUsuarioFornecedor.indRepresentacaoConjunto"
              :items="itemsSimENao"
              :label="`${$tc('label.representacao_conjunto', 1)}`"
              clearable
              item-value="shortCode"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete class="UsuarioFornecedorFiltros__Autocomplete custom-autocomplete"
              v-model="filtrosUsuarioFornecedor.indAtivo"
              :items="itemsSimENao"
              :label="`${$tc('label.ativo', 1)}`"
              clearable
              item-value="shortCode"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete class="UsuarioFornecedorFiltros__Autocomplete custom-autocomplete"
              v-model="filtrosUsuarioFornecedor.statusFluxo"
              :items="itemsStatusFluxo"
              :label="`${$tc('label.status_aprovacao', 1)}`"
              clearable
              item-value="shortCode"
              multiple
              chips
              deletable-chips
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-switch
              dense
              id="expiracao"
              :label="$t('label.expiracao')"
              v-model="filtrosUsuarioFornecedor.indExpiracao"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions v-show="filtrosAbertos">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
    </v-card>
  </template>
<script>
import { mapGetters } from 'vuex';
import {
  triggerSelecao,
} from '@/common/functions/autocomplete-utils';
import { copyObject } from '@/common/functions/helpers';
import { skipLoading } from '@/common/functions/loading';

export default {
  name: 'UsuarioFornecedorFiltros',
  mixins: [

  ],
  props: {
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    isTenantMagalu() {
      return this.getTenantId === 'magalu';
    },
    isTenantKabum() {
      return this.getTenantId === 'kabum';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      filtrosAbertos: false,
      filtrosFixados: false,
      carregouCampos: false,
      ordenacaoCampos: {},
      habilitaIconBadge: false,
      countFiltro: 0,
      itemsSimENao: [
        { shortCode: true, text: 'Sim' },
        { shortCode: false, text: 'Não' },
      ],
      itemsStatusFluxo: [
        { shortCode: 'AGUARDANDO_APROVACAO', text: this.$tc('status_entidade.aguardando_aprovacao', 1) },
        { shortCode: 'APROVADO', text: this.$tc('status_entidade.aprovado', 1) },
        { shortCode: 'EM_CADASTRO', text: this.$tc('status_entidade.em_cadastro', 1) },
        { shortCode: 'EM_ANALISE', text: this.$tc('status_entidade.em_analise', 1) },
        { shortCode: 'REPROVADO', text: this.$tc('status_entidade.reprovado', 1) },
        { shortCode: 'SEM_FLUXO', text: this.$tc('label.sem_fluxo', 1) },
      ],
      itemsTiposFornecedores: [
        { shortCode: true, text: this.$tc('label.tipos_fornecedor.representante_legal', 1) },
        { shortCode: false, text: this.$tc('label.tipos_fornecedor.aprovador_autorizado', 1) },
      ],
      itemsGrupoFornecedor: [],
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtroTipoFornecedor: null,
      filtrosUsuarioFornecedor: {},
      workspace: {},
      entidadeWorkspace: 'gestao_usuario_fornecedor',

    };
  },
  methods: {
    triggerSelecao,
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosUsuarioFornecedor = this.workspace.filtros || {};
          if (this.workspace.hasOwnProperty('filtros')) {
            if (this.workspace.filtros.hasOwnProperty('tipoFornecedor')) this.filtroTipoFornecedor = this.workspace.filtros.tipoFornecedor;
          }
          if (Object.values(this.filtrosUsuarioFornecedor).length > 0) {
            this.habilitaFlagBadge(true);
          }
          setTimeout(() => this.restaurarFiltros());
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosUsuarioFornecedor = {};
      this.filtroTipoFornecedor = null;
      this.habilitaIconBadge = false;
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      const params = this.validaFiltros();

      this.$emit('UsuarioFornecedorFiltros__AplicaFiltros', params);
    },
    validaFiltros() {
      const parametros = copyObject(this.filtrosUsuarioFornecedor);

      if (this.filtroTipoFornecedor !== null && this.filtroTipoFornecedor.length > 0) {
        parametros.indUsuarioExternoMaster = this.filtroTipoFornecedor;
      }

      if (parametros.hasOwnProperty('indExpiracao') && parametros.indExpiracao !== true) {
        delete parametros.indExpiracao;
      }

      if (parametros.hasOwnProperty('statusFluxo') && parametros.statusFluxo.includes('SEM_FLUXO')) {
        let index = 0;
        if (parametros.statusFluxo.length > 1) {
          index = parametros.statusFluxo.indexOf('SEM_FLUXO');
          parametros.statusFluxo.splice(index, 1);
          parametros.indSemFluxoMult = true;
        } else {
          parametros.statusFluxo.splice(index, 1);
          parametros.indSemFluxoUnico = true;
        }
      }

      Object.keys(parametros).forEach((param) => {
        if (parametros[param] === null) {
          delete parametros[param];
        }
      });

      return parametros;
    },
    aplicarFiltros() {
      this.salvarFiltrosWorkspace();
      this.restaurarFiltros();
    },
    salvarFiltrosWorkspace() {
      this.verificaCamposPreenchidosBadge();

      this.workspace.filtros = {
        ...this.filtrosUsuarioFornecedor,
      };

      if (this.filtroTipoFornecedor !== null && this.filtroTipoFornecedor.length > 0) {
        this.workspace.filtros.tipoFornecedor = this.filtroTipoFornecedor;
      }

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    verificaCamposPreenchidosBadge() {
      const filtroUsuario = this.validaFiltros();
      const filtroUsuarioValores = Object.values(filtroUsuario);
      this.habilitaBadge(filtroUsuarioValores);
    },
    buscarGruposFornecedores(filtro = null) {
      const value = {
        tamanhoPagina: 100000,
        idNivelExtensao: 3,
        nome: filtro,
      };
      this.extensaoResources.listarExtensoes(value).then((resultado) => {
        resultado.data.resposta.forEach((el) => {
          let nomeFornecedor = el.id.toString().concat(' - ').concat(el.idExterno);
          if (this.isTenantEpoca) {
            nomeFornecedor = el.idExterno.toString().concat(' - ').concat(el.nomExtensao);
          } else if (this.isTenantKabum) {
            nomeFornecedor = el.id.toString().concat(' - ').concat(el.nomExtensao);
          }
          const item = { shortCode: el.id, text: nomeFornecedor };
          this.itemsGrupoFornecedor.push(item);
        });
      });
    },
    habilitaBadge(filtroUsuario) {
      if (filtroUsuario.length !== 0) {
        filtroUsuario.forEach((item) => {
          if ((typeof item !== 'boolean' && typeof item !== 'number' && !item.length)) {
            this.countFiltro += 1;
          }
        });
        if (filtroUsuario.length === this.countFiltro) {
          this.filtrosUsuarioFornecedor = {};
          this.habilitaFlagBadge(false);
        } else {
          this.habilitaFlagBadge(true);
        }
        this.countFiltro = 0;
      } else {
        this.habilitaFlagBadge(false);
      }
    },
    habilitaFlagBadge(valor) {
      this.habilitaIconBadge = valor;
    },
    aplicaModelo(selecaoAtual) {
      if (this.multiplaEscolha) {
        this.itens = [...selecaoAtual];
      } else {
        this.itens.push(selecaoAtual);
      }
    },
  },
  mounted() {
    this.buscarGruposFornecedores();
    this.carregarWorkspaceFiltros();
  },
};
</script>
  <style>
    .UsuarioFornecedorFiltros__Autocomplete .chip--select-multi .v-chip__content span.trunc-ellipsis {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 150px;
    }
    .UsuarioFornecedorFiltros__Autocomplete .chip--select-multi {
      width: 195px;
    }
  </style>
